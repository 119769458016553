/* Common, default styles for the notification box */

.s-alert-box,
.s-alert-box * {
    box-sizing: border-box;
}

.s-alert-box {
    position: fixed;
    background: rgba(42,45,50,0.85);
    padding: 22px;
    line-height: 1.4;
    z-index: 1000;
    pointer-events: none;
    color: rgba(250,251,255,0.95);
    font-size: 100%;
    font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
    max-width: 300px;
    transition: top .4s, bottom .4s;
}

.s-alert-box.s-alert-show {
    pointer-events: auto;
}

.s-alert-box a {
    color: inherit;
    opacity: 0.7;
    font-weight: 700;
}

.s-alert-box a:hover,
.s-alert-box a:focus {
    opacity: 1;
}

.s-alert-box p {
    margin: 0;
}

.s-alert-box.s-alert-show,
.s-alert-box.s-alert-visible {
    pointer-events: auto;
}

.s-alert-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 4px;
    top: 4px;
    overflow: hidden;
    text-indent: 100%;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.s-alert-close:hover,
.s-alert-close:focus {
    outline: none;
}

.s-alert-close::before,
.s-alert-close::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 60%;
    top: 50%;
    left: 50%;
    background: #fff;
}

.s-alert-close:hover::before,
.s-alert-close:hover::after {
    background: #fff;
}

.s-alert-close::before {
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
    transform: translate(-50%,-50%) rotate(45deg);
}

.s-alert-close::after {
    -webkit-transform: translate(-50%,-50%) rotate(-45deg);
    transform: translate(-50%,-50%) rotate(-45deg);
}

/* positions */

.s-alert-bottom-left {
    top: auto;
    right: auto;
    bottom: 30px;
    left: 30px;
}
.s-alert-top-left {
    top: 30px;
    right: auto;
    bottom: auto;
    left: 30px;
}
.s-alert-top-right {
    top: 30px;
    right: 30px;
    bottom: auto;
    left: auto;
}
.s-alert-bottom-right { /*default*/
    top: auto;
    right: 30px;
    bottom: 30px;
    left: auto;
}
.s-alert-bottom {
    width: 100%;
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
}
.s-alert-top {
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
}

/* conditions */

.s-alert-info {
    background: #00A2D3;
    color: #fff;
}
.s-alert-success {
    background: #27AE60;
    color: #fff;
}
.s-alert-warning {
    background: #F1C40F;
    color: #fff;
}
.s-alert-error {
    background: #E74C3C;
    color: #fff;
}

[class^="s-alert-effect-"].s-alert-hide,
[class*=" s-alert-effect-"].s-alert-hide {
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

/* height measurement helper */
.s-alert-box-height {
    visibility: hidden;
    position: fixed;
}

/* stackslide (top, bottom - full width) */

.s-alert-effect-stackslide .s-alert-close {
    background: transparent;
}

.s-alert-effect-stackslide .s-alert-close::before,
.s-alert-effect-stackslide .s-alert-close::after {
    background: #fff;
}

.s-alert-effect-stackslide p {
    padding: 0 1.5em;
    display: inline-block;
}

.s-alert-effect-stackslide .s-alert-close::before,
.s-alert-effect-stackslide .s-alert-close::after {
    width: 2px;
    background: #fff;
}

.s-alert-effect-stackslide .s-alert-close:hover::before,
.s-alert-effect-stackslide .s-alert-close:hover::after {
    background: #fff;
}

.s-alert-top-left.s-alert-effect-stackslide.s-alert-show,
.s-alert-top-right.s-alert-effect-stackslide.s-alert-show,
.s-alert-top-left.s-alert-effect-stackslide.s-alert-hide,
.s-alert-top-right.s-alert-effect-stackslide.s-alert-hide,
.s-alert-top.s-alert-effect-stackslide.s-alert-show,
.s-alert-top.s-alert-effect-stackslide.s-alert-hide {
    -webkit-animation-name: animSlideTop;
    animation-name: animSlideTop;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

@-webkit-keyframes animSlideTop {
    0% { -webkit-transform: translate3d(0,-100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); }
}

@keyframes animSlideTop {
    0% { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

.s-alert-bottom-left.s-alert-effect-stackslide.s-alert-show,
.s-alert-bottom-right.s-alert-effect-stackslide.s-alert-show,
.s-alert-bottom-left.s-alert-effect-stackslide.s-alert-hide,
.s-alert-bottom-right.s-alert-effect-stackslide.s-alert-hide,
.s-alert-bottom.s-alert-effect-stackslide.s-alert-show,
.s-alert-bottom.s-alert-effect-stackslide.s-alert-hide {
    -webkit-animation-name: animSlideBottom;
    animation-name: animSlideBottom;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

@-webkit-keyframes animSlideBottom {
    0% { -webkit-transform: translate3d(0,100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); }
}

@keyframes animSlideBottom {
    0% { -webkit-transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}
/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4A90E2;
  color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

